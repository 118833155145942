(function ($,window) {
    var form = $('#select-company-partial-form');

    if (form.length === 0) {
        return;
    }

    form.on('change', function () {
        $(this).submit();
    });
})(jQuery,window);
