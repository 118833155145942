(function (window, $) {

    var timeEstimationsTableBody = $('#time-estimations tbody');
    var addButton = $('#add-time-estimation');
    var timeEstimationTemplate = $('[data-time-estimation-template]').detach().removeClass('hide').removeAttr('data-time-estimation-template');
    var emptyNotification = $('#time-estimations-empty');

    if (!timeEstimationsTableBody.length) {
        return;
    }

    addButton.on('click', addTimeEstimation);
    timeEstimationsTableBody.on('click', '.remove-time-estimation', removeTimeEstimation);

    function addTimeEstimation() {
        var timeEstimationRow = timeEstimationTemplate.clone();
        var key = getNewTimeEstimationKey();

        // Enable the inputs and replace placeholders in their names
        timeEstimationRow.find('input, select').each(function (index, element) {
            element = $(element);

            element.prop('disabled', false);
            element.attr('name', element.attr('name').replace(':key', key));
        });

        timeEstimationsTableBody.append(timeEstimationRow);

        setEmptyNotificationState();
    }

    function removeTimeEstimation(event) {
        $(event.target).closest('tr').remove();

        setEmptyNotificationState();
    }

    function setEmptyNotificationState() {
        if (timeEstimationsTableBody.find('tr').length > 0) {
            emptyNotification.addClass('hide');
            timeEstimationsTableBody.removeClass('hide').siblings('thead').removeClass('hide');
        } else {
            emptyNotification.removeClass('hide');
            timeEstimationsTableBody.addClass('hide').siblings('thead').addClass('hide');
        }
    }

    function getNewTimeEstimationKey() {
        var currentKeys = timeEstimationsTableBody.find('input[name^="time_estimation_descriptions"]').map(function (index, element) {
            var name = $(element).attr('name');
            var matches = name.match(/^[^\[\]]+\[(-[0-9]+)\]/);

            if (!matches || typeof matches[1] === undefined) {
                return undefined;
            }

            return parseInt(matches[1], 10);
        });

        // Find the lowest current key
        var min = currentKeys.get().reduce(function (previous, current) {
            return previous < current ? previous : current;
        }, 0);

        return min - 1;
    }

})(window, jQuery);
