(function (window, $) {

    $('body').on('click', '.delete', deleteResource);
    $('body').on('click', '.restore', restoreResource);

    function deleteResource(e) {
        e.preventDefault();

        var elem = $(this);
        var row = elem.closest('tr');
        var table = row.closest('table');

        if (table.attr('data-confirm-delete')) {
            if (!window.confirm(table.attr('data-confirm-delete'))) {
                return;
            }
        }

        elem.prop('disabled', true);

        $.ajax({
            type: 'delete',
            url: elem.attr('data-url'),
            dataType: 'json',
            success: function (data) {
                if (data.type === 'alert-success') {
                    row.trigger('fadingout');
                    row.fadeOut(function () {
                        row.trigger('fadedout');
                        row.detach();
                        row.trigger('detached');
                    });
                } else {
                    elem.prop('disabled', false);
                    alert(data.content);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                elem.prop('disabled', false);

                if (jqXHR.responseText) {
                    try {
                        var data = JSON.parse(jqXHR.responseText);

                        alert(data.content);
                    } catch (SyntaxError) {
                        alert(jqXHR.responseText);
                    }
                } else {
                    alert('Error');
                }
            }
        });
    }

    function restoreResource(e) {
        e.preventDefault();

        var elem = $(this);
        var row = elem.closest('tr');
        var table = row.closest('table');

        if (table.attr('data-confirm-restore')) {
            if (!window.confirm(table.attr('data-confirm-restore'))) {
                return;
            }
        }

        elem.prop('disabled', true);

        $.ajax({
            type: 'put',
            url: elem.attr('data-url'),
            dataType: 'json',
            success: function (data) {
                if (data.type === 'alert-success') {
                    row.trigger('fadingout');
                    row.fadeOut(function () {
                        row.trigger('fadedout');
                        row.detach();
                        row.trigger('detached');
                    });
                } else {
                    elem.prop('disabled', false);
                    alert(data.content);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                elem.prop('disabled', false);

                if (jqXHR.responseText) {
                    try {
                        var data = JSON.parse(jqXHR.responseText);

                        alert(data.content);
                    } catch (SyntaxError) {
                        alert(jqXHR.responseText);
                    }
                } else {
                    alert('Error');
                }
            }
        });
    }

})(window, jQuery);
