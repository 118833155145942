(function (window, $) {

    $('body').on('click', '.show-task', function (event) {
        event.preventDefault();

        var url = $(this).attr('href');
        var modal = $('#task-modal');

        modal.find('.modal-dialog').load(url, undefined, function () {
            modal.modal('show');
        });
    });

})(window, jQuery);
