(function (window, $) {

    if (!$.fn.datetimepicker) {
        return;
    }

    $('[data-datepicker="true"]').each(function (index, element) {
        element = $(element);

        var options = {
            locale: 'sv'
        };

        if (element.attr('data-datepicker-locale')) {
            options.locale = element.attr('data-datepicker-locale');
        }

        if (element.attr('data-datepicker-format')) {
            options.format = element.attr('data-datepicker-format');
        }

        element.datetimepicker(options);
    });

})(window, jQuery);
