(function (window, $) {

    var companySelect = $('[name="company_id"]');

    if (!companySelect.length) {
        return;
    }

    var companyElements = $('[data-company-id]');

    companySelect.on('change', showCompanyElements);

    showCompanyElements();

    function showCompanyElements() {
        var companyId = companySelect.val();

        companyElements.each(function (index, element) {
            element = $(element);

            if (element.attr('data-company-id') === companyId) {
                if (element.is('input, select')) {
                    element.prop('disabled', false);
                } else {
                    element.find('input, select').each(function (inputIndex, inputElement) {
                        $(inputElement).prop('disabled', false);
                    });
                }

                element.removeClass('hide');
            } else {
                if (element.is('input, select')) {
                    element.prop('disabled', true);
                } else {
                    element.find('input, select').each(function (inputIndex, inputElement) {
                        $(inputElement).prop('disabled', true);
                    });
                }

                element.addClass('hide');
            }
        });
    }

})(window, jQuery);
