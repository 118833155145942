(function (window, $) {

    if (typeof tinymce === 'undefined') {
        return;
    }

    var TinyMCEManager = function () {
        this.settings = {
            entity_encoding: 'raw',
            plugins: 'code image link pagebreak',
            convert_urls: false,
            relative_urls: false,
            image_dimensions: false,
            pagebreak_separator: '<span class="pagebreak"></span>',
            toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | pagebreak'
        };

        this.setLocale();
    };

    /**
     * Initialize a set of editors.
     *
     * @param  String  selector  The selector for the editors
     * @return undefined
     */
    TinyMCEManager.prototype.init = function (selector) {
        selector = selector || '.wysiwyg';

        var settings = $.extend({}, this.settings, {selector: selector});

        tinymce.init(settings);
    };

    /**
     * Remove a set of editors.
     *
     * @param  String  selector  The selector for the editors
     * @return undefined
     */
    TinyMCEManager.prototype.remove = function (selector) {
        tinymce.remove(selector);
    };

    /**
     * Set the locale for the editors.
     *
     * @return undefined
     */
    TinyMCEManager.prototype.setLocale = function () {
        tinymce.addI18n(
            'sv_SE',
            {
                "Cut": "Klipp ut",
                "Heading 5": "Rubrik 5",
                "Header 2": "Rubrik 2",
                "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X\/C\/V keyboard shortcuts instead.": "Din browser st\u00f6djer inte direkt \u00e5tkomst till klippboken. V\u00e4nligen anv\u00e4nd kortkommandona Ctrl+X\/C\/V i st\u00e4llet.",
                "Heading 4": "Rubrik 4",
                "Div": "Div",
                "Heading 2": "Rubrik 2",
                "Paste": "Klistra in",
                "Close": "St\u00e4ng",
                "Font Family": "Teckensnitt",
                "Pre": "F\u00f6rformaterad",
                "Align right": "H\u00f6gerst\u00e4ll",
                "New document": "Nytt dokument",
                "Blockquote": "Blockcitat",
                "Numbered list": "Nummerlista",
                "Heading 1": "Rubrik 1",
                "Headings": "Rubriker",
                "Increase indent": "\u00d6ka indrag",
                "Formats": "Format",
                "Headers": "Rubriker",
                "Select all": "Markera allt",
                "Header 3": "Rubrik 3",
                "Blocks": "Block",
                "Undo": "\u00c5ngra",
                "Strikethrough": "Genomstruken",
                "Bullet list": "Punktlista",
                "Header 1": "Rubrik 1",
                "Superscript": "Upph\u00f6jd text",
                "Clear formatting": "Avformatera",
                "Font Sizes": "Storlek",
                "Subscript": "Neds\u00e4nkt text",
                "Header 6": "Rubrik 6",
                "Redo": "G\u00f6r om",
                "Paragraph": "Br\u00f6dtext",
                "Ok": "Ok",
                "Bold": "Fetstil",
                "Code": "Kod",
                "Italic": "Kursiv stil",
                "Align center": "Centrera",
                "Header 5": "Rubrik 5",
                "Heading 6": "Rubrik 6",
                "Heading 3": "Rubrik 3",
                "Decrease indent": "Minska indrag",
                "Header 4": "Rubrik 4",
                "Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.": "Klistra in \u00e4r nu i textl\u00e4ge. Inneh\u00e5ll kommer att konverteras till text tills du sl\u00e5r av detta l\u00e4ge.",
                "Underline": "Understruken",
                "Cancel": "Avbryt",
                "Justify": "Justera",
                "Inline": "Inline",
                "Copy": "Kopiera",
                "Align left": "V\u00e4nsterst\u00e4ll",
                "Visual aids": "Visuella hj\u00e4lpmedel",
                "Lower Greek": "Grekiska gemener",
                "Square": "Fyrkant",
                "Default": "Original",
                "Lower Alpha": "Gemener",
                "Circle": "Cirkel",
                "Disc": "Disk",
                "Upper Alpha": "Versaler",
                "Upper Roman": "Romerska versaler",
                "Lower Roman": "Romerska gemener",
                "Name": "Namn",
                "Anchor": "Ankare",
                "You have unsaved changes are you sure you want to navigate away?": "Du har f\u00f6r\u00e4ndringar som du inte har sparat. \u00c4r du s\u00e4ker p\u00e5 att du vill navigera vidare?",
                "Restore last draft": "\u00c5terst\u00e4ll senaste utkast",
                "Special character": "Specialtecken",
                "Source code": "K\u00e4llkod",
                "Color": "F\u00e4rg",
                "Right to left": "H\u00f6ger till v\u00e4nster",
                "Left to right": "V\u00e4nster till h\u00f6ger",
                "Emoticons": "Emoticons",
                "Robots": "Robotar",
                "Document properties": "Dokumentegenskaper",
                "Title": "Titel",
                "Keywords": "Nyckelord",
                "Encoding": "Encoding",
                "Description": "Beskrivning",
                "Author": "F\u00f6rfattare",
                "Fullscreen": "Fullsk\u00e4rm",
                "Horizontal line": "Horisontell linje",
                "Horizontal space": "Horisontellt utrymme",
                "Insert\/edit image": "Infoga\/redigera bild",
                "General": "Generella",
                "Advanced": "Avancerat",
                "Source": "K\u00e4lla",
                "Border": "Ram",
                "Constrain proportions": "Begr\u00e4nsa proportioner",
                "Vertical space": "Vertikaltutrymme",
                "Image description": "Bildbeskrivning",
                "Style": "Stil",
                "Dimensions": "Dimensioner",
                "Insert image": "Infoga bild",
                "Insert date\/time": "Infoga datum\/tid",
                "Remove link": "Ta bort l\u00e4nk",
                "Url": "Url",
                "Text to display": "Text att visa",
                "Anchors": "Bokm\u00e4rken",
                "Insert link": "Infoga l\u00e4nk",
                "New window": "Nytt f\u00f6nster",
                "None": "Ingen",
                "The URL you entered seems to be an external link. Do you want to add the required http:\/\/ prefix?": "Urlen du angav verkar vara en extern l\u00e4nk. Vill du l\u00e4gga till http:\/\/ prefixet?",
                "Target": "M\u00e5l",
                "The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?": "Urlen du angav verkar vara en epost adress. Vill du l\u00e4gga till ett mailto: prefix?",
                "Insert\/edit link": "Infoga\/redigera l\u00e4nk",
                "Insert\/edit video": "Infoga\/redigera video",
                "Poster": "Affish",
                "Alternative source": "Alternativ k\u00e4lla",
                "Paste your embed code below:": "Klistra in din inb\u00e4ddningskod nedan:",
                "Insert video": "Infoga video",
                "Embed": "Inb\u00e4ddning",
                "Nonbreaking space": "Avbrottsfritt mellanrum",
                "Page break": "Sidbrytning",
                "Paste as text": "Klistra in som text",
                "Preview": "F\u00f6rhandsgranska",
                "Print": "Skriv ut",
                "Save": "Spara",
                "Could not find the specified string.": "Kunde inte hitta den specifierade st\u00e4ngen.",
                "Replace": "Ers\u00e4tt",
                "Next": "N\u00e4sta",
                "Whole words": "Hela ord",
                "Find and replace": "S\u00f6k och ers\u00e4tt",
                "Replace with": "Ers\u00e4tt med",
                "Find": "S\u00f6k",
                "Replace all": "Ers\u00e4tt alla",
                "Match case": "Matcha gemener\/versaler",
                "Prev": "F\u00f6reg\u00e5ende",
                "Spellcheck": "R\u00e4ttstava",
                "Finish": "Avsluta",
                "Ignore all": "Ignorera alla",
                "Ignore": "Ignorera",
                "Add to Dictionary": "L\u00e4gg till i ordlista",
                "Insert row before": "Infoga rad f\u00f6re",
                "Rows": "Rader",
                "Height": "H\u00f6jd",
                "Paste row after": "Klistra in rad efter",
                "Alignment": "Justering",
                "Border color": "Ramf\u00e4rg",
                "Column group": "Kolumngrupp",
                "Row": "Rad",
                "Insert column before": "Infoga kolumn f\u00f6re",
                "Split cell": "Bryt is\u00e4r celler",
                "Cell padding": "Cellpaddning",
                "Cell spacing": "Cellmellanrum",
                "Row type": "Radtyp",
                "Insert table": "Infoga tabell",
                "Body": "Kropp",
                "Caption": "Rubrik",
                "Footer": "Fot",
                "Delete row": "Radera rad",
                "Paste row before": "Klista in rad f\u00f6re",
                "Scope": "Omf\u00e5ng",
                "Delete table": "Radera tabell",
                "H Align": "H-justering",
                "Top": "Toppen",
                "Header cell": "Huvudcell",
                "Column": "Kolumn",
                "Row group": "Radgrupp",
                "Cell": "Cell",
                "Middle": "Mitten",
                "Cell type": "Celltyp",
                "Copy row": "Kopiera rad",
                "Row properties": "Radegenskaper",
                "Table properties": "Tabellegenskaper",
                "Bottom": "Botten",
                "V Align": "V-justering",
                "Header": "Huvud",
                "Right": "H\u00f6ger",
                "Insert column after": "Infoga kolumn efter",
                "Cols": "Kolumner",
                "Insert row after": "Infoga rad efter",
                "Width": "Bredd",
                "Cell properties": "Cellegenskaper",
                "Left": "V\u00e4nster",
                "Cut row": "Klipp ut rad",
                "Delete column": "Radera kolumn",
                "Center": "Centrum",
                "Merge cells": "Sammanfoga celler",
                "Insert template": "Infoga mall",
                "Templates": "Mallar",
                "Background color": "Bakgrundsf\u00e4rg",
                "Custom...": "Anpassad...",
                "Custom color": "Anpassad f\u00e4rg",
                "No color": "Ingen f\u00e4rg",
                "Text color": "Textf\u00e4rg",
                "Show blocks": "Visa block",
                "Show invisible characters": "Visa onsynliga tecken",
                "Words: {0}": "Ord: {0}",
                "Insert": "Infoga",
                "File": "Fil",
                "Edit": "Redigera",
                "Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help": "Textredigerare. Tryck ALT-F9 f\u00f6r menyn. Tryck ALT-F10 f\u00f6r verktygsrader. Tryck ALT-0 f\u00f6r hj\u00e4lp.",
                "Tools": "Verktyg",
                "View": "Visa",
                "Table": "Tabell",
                "Format": "Format"
            }
        );
    };

    if (!window.viwebb) {
        window.viwebb = {};
    }

    // Provide global access to the class
    window.viwebb.TinyMCEManager = TinyMCEManager;

    // Create an instance of the class
    window.viwebb.tinyMCEManager = new TinyMCEManager();
    window.viwebb.tinyMCEManager.init();

})(window, jQuery);
