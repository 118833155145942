(function (window, $) {
    $('body').on('click', '.set-task-status', setTaskStatus);

    function setTaskStatus(e) {
        e.preventDefault();

        var elem = $(this);
        var row = elem.closest('tr');
        var table = row.closest('table');
        var status = elem.attr('data-task-status');

        elem.prop('disabled', true);

        $.ajax({
            type: 'put',
            url: elem.attr('data-url'),
            dataType: 'json',
            data: {status: status},
            success: function (data) {
                if (data.type === 'alert-success') {
                    row.trigger('fadingout');
                    row.fadeOut(function () {
                        row.trigger('fadedout');
                        row.detach();
                        row.trigger('detached');

                        loadTasksWithStatus(status);
                    });
                } else {
                    elem.prop('disabled', false);
                    alert(data.content);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                elem.prop('disabled', false);

                if (jqXHR.responseText) {
                    try {
                        var data = JSON.parse(jqXHR.responseText);

                        alert(data.content);
                    } catch (SyntaxError) {
                        alert(jqXHR.responseText);
                    }
                } else {
                    alert('Error');
                }
            }
        });
    }

    function loadTasksWithStatus(status) {
        var wrapper = $('[data-task-wrapper][data-task-status="' + status + '"]');

        if (!wrapper.length) {
            return;
        }

        wrapper.load(wrapper.attr('data-url'));
    }
})(window, jQuery);
